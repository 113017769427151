import { Injectable } from '@angular/core';
import { set, get, del } from 'idb-keyval';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenKey = 'AUTH_TOKEN';

  saveToken(data): Promise<void> {
    return set(this.tokenKey, data);
  }

  getToken(): Promise<unknown> {
    return get(this.tokenKey);
  }

  async getDecodedTokenData(): Promise<any> {
    try {
      const token: any = await this.getToken();
      if (!token) {
        return null;
      }

      return JSON.parse(window.atob(token.split('.')[1]));

    } catch {
      return null;
    }
  }


  async isTokenExist(): Promise<boolean> {
    try {
      return await this.getToken() ? true : false;
    } catch {
      return false;
    }
  }

  removeToken(): Promise<void> {
    return del(this.tokenKey);
  }

}
