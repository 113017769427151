import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { TokenService } from '../token/token.service';

export enum Roles {
  admin = 'admin',
  employee = 'employee',
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http = inject(HttpClient);
  private settings = inject(SettingsService);
  private token = inject(TokenService);
  private router = inject(Router);


  subject = new BehaviorSubject<Promise<boolean>>(this.isLoggedIn());

  login({ mobile, password }): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/auth/login`;
    return this.http.post<any>(url, { mobile, password }).toPromise();
  }

  isLoggedIn(): Promise<boolean> {
    return this.token.isTokenExist();
  }

  async getRole(): Promise<string | null> {
    const data = await this.token.getDecodedTokenData();

    if (data) {
      return data.role;
    } else {
      return null;
    }
  }

  async logout(): Promise<void> {
   try {
    await this.token.removeToken();
    this.subject.next(Promise.resolve(false));
    this.router.navigate(['/login']);
   } catch {
    console.log('Failed to login')
   }
  }
}
